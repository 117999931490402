import App from "@/views/App.vue";

export default [
  {
    path: "",
    name: "onboarding",
    component: App,
    redirect: { name: "onboardingPage" },
    children: [
      {
        path: "/onboarding/:user/:language?/:redirect_to?/:redirect_params?/:redirect_params_last?",
        name: "onboardingPage",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Onboarding.vue"),
        meta: {
          displayName: "Onboarding - Exclusive Lounge",
          requiresAuth: false,
        },
      },
      {
        path: "/login",
        name: "loginPage",
        alias: "/",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Login.vue"),
        meta: {
          displayName: "Login - Exclusive Lounge",
          requiresAuth: false,
        },
      },
    ],
  },
];
