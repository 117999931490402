
import i18n from "@/plugins/i18n";
import { TranslateResult } from "vue-i18n";
import { Prop, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import Component, { mixins } from "vue-class-component";
import { ActionMixin } from "../mixins/ActionMixin";
import { TokenService } from "@/api/storage";
type sidebar_items = {
  image: any;
  imageSelected: any;
  moduleName: string;
  name: string | TranslateResult;
  route: string;
};
@Component
export default class TheSidebar extends mixins(ActionMixin) {
  @Prop({ default: false }) toggle: boolean;
  @Prop({ default: false }) mini: boolean;
  selected = null;
  generalItems = [
    {
      imageSelected: require("@/assets/unionTimeline.svg"),
      image: require("@/assets/unionTimelineGray.svg"),
      moduleName: "timeline",
      name: "Timeline",
      route: "timelineHome",
    },
    {
      imageSelected: require("@/assets/purple_news.svg"),
      image: require("@/assets/grey_news.svg"),
      moduleName: "readingList",
      name: i18n.t("readingList.readingList"),
      route: "readingListHome",
    },
    {
      imageSelected: require("@/assets/leadsPurple.svg"),
      image: require("@/assets/leadsGray.svg"),
      moduleName: "leads",
      name: i18n.t("leads.leads"),
      route: "leadsHome",
    },
    // {
    //   imageSelected: require("@/assets/ic_notificacoes.svg"),
    //   image: require("@/assets/ic_notificacoes_purple.svg"),
    //   moduleName: "notification",
    //   name: i18n.t("notification.title"),
    //   route: "notificationHome",
    // },
    {
      imageSelected: require("@/assets/mensagem_in_off_purple.svg"),
      image: require("@/assets/mensagem_white.svg"),
      moduleName: "chat",
      name: i18n.t("chat.title"),
      route: "chatHome",
    },
    {
      imageSelected: require("@/assets/participantesMini.svg"),
      image: require("@/assets/participantesTimeline.svg"),
      moduleName: "profile",
      name: i18n.t("misc.profile"),
      route: "profileHome",
    },
    {
      imageSelected: require("@/assets/exit.png"),
      image: require("@/assets/exit.png"),
      moduleName: "loginPage",
      name: "Logout",
      route: "loginPage",
    },
  ];
  drawer = false;
  @Action("profile/requestUpdateTheme") requestUpdateTheme: any;
  @Action("requestLogout") requestLogout: any;
  get classMiniSidebar(): string {
    return this.mini ? "px-4" : "px-2";
  }
  get sidebarItems(): any {
    return TokenService.getRole() === "EXECUTIVE"
      ? this.generalItems
      : this.generalItems.filter((item: any) => item.route !== "leadsHome");
  }
  mounted(): void {
    let currentItem: any;
    this.generalItems.forEach((item: sidebar_items) => {
      let route = this.$route.name;
      if (route?.includes(item.moduleName)) currentItem = item;
    });
    this.selected = currentItem;
  }
  updateMiniSidebar(): void {
    this.mini = !this.mini;
  }
  isSeletecItem(item: sidebar_items): boolean {
    return this.selected == item;
  }
  updateSelectedItem(item: any): void {
    this.selected = this.selected === item ? null : item;
    if (item.route === "loginPage") {
      this.requestLogout();
    }
    if (item.moduleName) this.$router.push({ name: item.moduleName });
  }
  backgroundSelectedItem(item: sidebar_items): string {
    return this.isSeletecItem(item)
      ? "white purple100--text"
      : "white gray70--text";
  }
  async updateTheme(item: boolean): Promise<void> {
    const payload = {
      theme: item ? "DARK" : "LIGHT",
    };
    await this.withLoading(async () => {
      await this.requestUpdateTheme(payload);
    });
  }
  redirectTimeline(): void {
    if (this.$route.name !== "imelineHome")
      this.$router.push({ name: "timelineHome" });
  }
  @Watch("toggle", { immediate: true }) onItemChanged(): void {
    this.drawer = this.toggle;
  }
}
