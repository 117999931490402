import API from "../api";
import { TokenService } from "@/api/storage";
import { initialize } from "@/api";
import {
  UserToken,
  PayloadAuth,
  PayloadOnboarding,
  PayloadLogin,
  PayloadEmail,
} from "../types";
import { Commit, Dispatch } from "vuex";
export default {
  namespaced: true,
  state: {
    firstAccess: false,
    loginUser: {
      email: "",
      senha: "",
    },
  },
  mutations: {
    setFirstAccess(state: any, payload: boolean) {
      state.firstAccess = payload;
    },
    setLoginUser(state: any, payload: PayloadLogin) {
      state.loginUser = payload;
    },
  },
  actions: {
    storeToken(context: any, payload: UserToken): void {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
      TokenService.saveToken(JSON.stringify(payload.token));
      if (payload.refresh) {
        TokenService.saveRefreshToken(JSON.stringify(payload.refresh));
      }
      initialize(payload.token);
    },
    storeTokenUrl(context: any, payload: string): void {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
      TokenService.saveToken(JSON.stringify(payload));
      initialize(payload);
    },
    async requestLoginAuth(
      { dispatch }: { dispatch: Dispatch },
      payload: PayloadAuth
    ): Promise<UserToken> {
      const { data } = await API.getToken(payload);
      dispatch("storeToken", data);
      return data;
    },
    async requestLogin(
      { dispatch }: { dispatch: Dispatch },
      payload: PayloadOnboarding
    ): Promise<UserToken> {
      const { data } = await API.getTokenUser(payload);
      dispatch("storeToken", data);
      return data;
    },
    initializeAxios(): void {
      initialize(TokenService.getToken());
    },
    async requestAuth(
      { dispatch }: { dispatch: Dispatch },
      payload: PayloadLogin
    ): Promise<UserToken> {
      const { data } = await API.getLogin(payload);
      dispatch("storeToken", data);
      dispatch("updateLogin", payload);
      return data;
    },
    async requestRecoverPassword(
      context: any,
      payload: PayloadEmail
    ): Promise<any> {
      const { data } = await API.postRecoverPassword(payload);
      return data;
    },
    updateFirstAccess({ commit }: { commit: Commit }, payload: boolean): any {
      TokenService.saveFirstAccess(String(payload));
      commit("setFirstAccess", payload);
    },
    updateLogin({ commit }: { commit: Commit }, payload: PayloadLogin): any {
      commit("setLoginUser", payload);
    },
  },
  modules: {},
};
