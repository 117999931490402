import { api } from "@/api";
import { AxiosResponse } from "axios";
import { PayloadRepost } from "@/types";
class API {
  static postNew(payload: any): Promise<AxiosResponse> {
    return api.post("/post", payload);
  }
  static getEvents(): Promise<AxiosResponse> {
    return api.get("/event/list");
  }
  static getCategories(): Promise<AxiosResponse> {
    return api.get("/post/categories");
  }
  static postNewMartekingPost(payload: any): Promise<AxiosResponse> {
    return api.post("/post", payload);
  }
  static getQuota(): Promise<AxiosResponse> {
    return api.get("/cota");
  }
  static putMartekingPost(payload: any): Promise<AxiosResponse> {
    return api.put("/post", payload);
  }
  static getUsersMark(payload: any): Promise<AxiosResponse> {
    return api.get("/profile/markable-users", { params: payload });
  }
  static postRepost(payload: PayloadRepost): Promise<AxiosResponse> {
    return api.post("/post/repost", payload);
  }
  static putRepost(payload: PayloadRepost): Promise<AxiosResponse> {
    return api.put("/post/repost", payload);
  }
  static getActiveEvents(): Promise<AxiosResponse> {
    return api.get("/event/list/active");
  }
}

export default API;
