import { api } from "@/api";
import { AxiosResponse } from "axios";
import { PayloadPostsCommunity, PayloadStatusCommunity } from "../types";
class API {
  static getCurrentCommunity(payload: number): Promise<AxiosResponse> {
    return api.get(`/community/${payload}`);
  }
  static getFeedCommunity(
    payload: PayloadPostsCommunity
  ): Promise<AxiosResponse> {
    return api.get(`/community/${payload.communityId}/posts`, {
      params: payload.params,
    });
  }
  static getCommunities(): Promise<AxiosResponse> {
    return api.get("/community/list");
  }
  static getCommunityMembers(payload: any): Promise<AxiosResponse> {
    return api.get(`community/${payload.id}/users`, { params: payload.params });
  }
  static putStatusCommunity(
    payload: PayloadStatusCommunity
  ): Promise<AxiosResponse> {
    return api.put("/community/profile/status", payload);
  }
  static getIsNewCommunity(): Promise<AxiosResponse> {
    return api.get("/community/news");
  }
  static putNotificationCommunity(): Promise<AxiosResponse> {
    return api.put("/community/news");
  }
  static getCommunitiesActives(): Promise<AxiosResponse> {
    return api.get("/community/list/actives");
  }
  static getCommunityPending(): Promise<AxiosResponse> {
    return api.get("/community/pending");
  }
  static postNewCommunity(payload: any): Promise<AxiosResponse> {
    return api.post("/community", payload);
  }
  static putEditCommunity(payload: any): Promise<AxiosResponse> {
    return api.put("/community", payload);
  }
  static putInactivateCommunity(payload: any): Promise<AxiosResponse> {
    return api.put(`/community/${payload.communityId}/inactivate`, {
      params: payload,
    });
  }
  static getUsersToInvite(payload: any): Promise<AxiosResponse> {
    return api.get(`/community/${payload.communityId}/search/users/toInvite`, {
      params: payload.params,
    });
  }
  static putInviTeUser(payload: any): Promise<AxiosResponse> {
    return api.put(`/community/${payload.communityId}/invite`, payload);
  }
  static putInactivateUser(payload: any): Promise<AxiosResponse> {
    return api.put(
      `/community/${payload.communityId}/inactivate/${payload.profileId}`,
      payload
    );
  }
}

export default API;
