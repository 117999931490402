import API from "../api";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async requestNewRoundTable(context: any, payload: any): Promise<any> {
      const { data } = await API.postNewRoundTable(payload);
      return data;
    },
    async requestNewPostRoundTable(context: any, payload: any): Promise<any> {
      const { data } = await API.postNewPostRoundTable(payload);
      return data;
    },
    async requestEditPostRoundTable(context: any, payload: any): Promise<any> {
      const { data } = await API.putEditPostRoundTable(payload);
      return data;
    },
    async requestRoundTable(context: any, payload: any): Promise<any> {
      const { data } = await API.getRoundTable(payload);
      return data;
    },
    async requestNewCommentRoundTable(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.postCommentRoundTable(payload);
      return data;
    },
    async requestEditCommentRoundTable(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.putCommentRoundTable(payload);
      return data;
    },
    async requestDeleteCommentRoundTable(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.deleteCommentRoundTable(payload);
      return data;
    },
  },
  modules: {},
};
