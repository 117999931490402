import API from "../api";
import { Event, QuotaItem } from "../../../types";
import { PayloadRepost } from "@/types";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async requestNewPost(context: any, payload: any): Promise<any> {
      const { data } = await API.postNew(payload);
      return data;
    },
    async requestEvents(): Promise<Event[]> {
      const { data } = await API.getEvents();
      return data;
    },
    async requestCategories(): Promise<Event[]> {
      const { data } = await API.getCategories();
      return data;
    },
    async requestNewMarketingPost(context: any, payload: any): Promise<any> {
      const { data } = await API.postNewMartekingPost(payload);
      return data;
    },
    async requestQuota(): Promise<QuotaItem[]> {
      const { data } = await API.getQuota();
      return data;
    },
    async requestEditMarketingPost(context: any, payload: any): Promise<any> {
      const { data } = await API.putMartekingPost(payload);
      return data;
    },
    async requestUsersMark(context: any, payload: any): Promise<any> {
      const { data } = await API.getUsersMark(payload);
      return data;
    },
    async requestRepost(context: any, payload: PayloadRepost): Promise<any> {
      const { data } = await API.postRepost(payload);
      return data;
    },
    async requestEditRepost(
      context: any,
      payload: PayloadRepost
    ): Promise<any> {
      const { data } = await API.putRepost(payload);
      return data;
    },
    async requestActiveEvents(): Promise<Event[]> {
      const { data } = await API.getActiveEvents();
      return data;
    },
  },
  modules: {},
};
