import App from "@/views/App.vue";

export default [
  {
    path: "/leads",
    name: "leads",
    component: App,
    redirect: { name: "leadsHome" },
    children: [
      {
        path: "",
        name: "leadsHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/LeadsList.vue"),
        meta: {
          displayName: "Leads - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
