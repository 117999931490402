import { api } from "@/api";
import { AxiosResponse } from "axios";
import {
  InterestSuggest,
  PayloadUsers,
  PayloadBlockProfile,
  CardDelete,
  CardGroupDelete,
  PayloadCardPosition,
  PayloadCardGroupPosition,
  PayloadEditCardGroup,
  PayloadNewCardGroup,
  PayloadCurrentCard,
  PayloadEditCard,
  PayloadUserFollow,
  PayloadFollow,
} from "../types";
class API {
  static getCurrentProfile(): Promise<AxiosResponse> {
    return api.get("/profile/");
  }
  static putCurrentProfile(payload: any): Promise<AxiosResponse> {
    return api.put("/profile", payload);
  }
  static getInterest(payload: string): Promise<AxiosResponse> {
    return api.get(`/interest/list${payload}`);
  }
  static postInterest(payload: InterestSuggest): Promise<AxiosResponse> {
    return api.post("/interest/suggest", payload);
  }
  static getUsers(payload: PayloadUsers): Promise<AxiosResponse> {
    return api.get("/profile/search", { params: payload });
  }
  static getMe(): Promise<AxiosResponse> {
    return api.get("/user/me");
  }
  static getUserProfile(payload: string): Promise<AxiosResponse> {
    return api.get(`/profile/${payload}`);
  }
  static putProfileImage(payload: any): Promise<AxiosResponse> {
    return api.put("/profile/image", payload);
  }
  static postBlockProfile(
    payload: PayloadBlockProfile
  ): Promise<AxiosResponse> {
    return api.post("/profile/block", payload);
  }
  static postUnblockProfile(
    payload: PayloadBlockProfile
  ): Promise<AxiosResponse> {
    return api.post("/profile/unblock", payload);
  }
  static getUser(payload: string): Promise<AxiosResponse> {
    return api.get(`/profile/mini-profile/${payload}`);
  }
  static getUserStatus(payload: string): Promise<AxiosResponse> {
    return api.get(`/user/status/${payload}`);
  }
  static putProfileTheme(payload: string): Promise<AxiosResponse> {
    return api.put(`/profile/update-theme${payload}`);
  }
  static getCardGroups(): Promise<AxiosResponse> {
    return api.get("/card-group");
  }
  static deleteCard(payload: CardDelete): Promise<AxiosResponse> {
    return api.delete("/card", { params: payload });
  }
  static deleteCardGroup(payload: CardGroupDelete): Promise<AxiosResponse> {
    return api.delete("/card-group", { params: payload });
  }
  static putCardGroupPosition(
    payload: PayloadCardGroupPosition
  ): Promise<AxiosResponse> {
    return api.put("/card-group/update-position", payload);
  }
  static putCardPosition(payload: PayloadCardPosition): Promise<AxiosResponse> {
    return api.put("/card/update-position", payload);
  }
  static postCardGroup(payload: PayloadNewCardGroup): Promise<AxiosResponse> {
    return api.post("/card-group", payload);
  }
  static putCardGroup(payload: PayloadEditCardGroup): Promise<AxiosResponse> {
    return api.put("/card-group", payload);
  }
  static getCurrentCard(payload: PayloadCurrentCard): Promise<AxiosResponse> {
    return api.get("/card/by-id", { params: payload });
  }
  static postCard(payload: PayloadEditCard): Promise<AxiosResponse> {
    return api.post("/card", payload);
  }
  static putCard(payload: PayloadEditCard): Promise<AxiosResponse> {
    return api.put("/card", payload);
  }
  static getAbout(): Promise<AxiosResponse> {
    return api.get("/about/");
  }
  static putAbout(payload: any): Promise<AxiosResponse> {
    return api.put("/about/", payload);
  }
  static getCategories(): Promise<AxiosResponse> {
    return api.get("/interest/categories");
  }
  static getAllEvents(): Promise<AxiosResponse> {
    return api.get("/event/list/all");
  }
  static postCase(payload: any): Promise<AxiosResponse> {
    return api.post("/success_case_registration", payload);
  }
  static getTermsCase(): Promise<AxiosResponse> {
    return api.get("/terms/success-case");
  }
  static getInfoMedalInteraction(): Promise<AxiosResponse> {
    return api.get("/medals/info");
  }
  static getUserFollwing(payload: PayloadUserFollow): Promise<AxiosResponse> {
    return api.get(`/follower/${payload.id}/influencers`, {
      params: payload.params,
    });
  }
  static getUserFollowers(payload: PayloadUserFollow): Promise<AxiosResponse> {
    return api.get(`/follower/${payload.id}/followers`, {
      params: payload.params,
    });
  }
  static putFollow(payload: PayloadFollow): Promise<AxiosResponse> {
    return api.put(`/follower/${payload.id}/${payload.status}`);
  }
  static putAddedToLeads(payload: any): Promise<AxiosResponse> {
    return api.put(
      `/profile/lead/${payload.profileId}/${payload.status}`,
      payload
    );
  }
}

export default API;
