const TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";
const LANGUAGE_KEY = "language";
const FIRST_ACCESS = "firstAccess";
const ROLE = "role";
const TAB = "tab";

const TokenService = {
  getToken(): string {
    return JSON.parse(localStorage.getItem(TOKEN_KEY) || "null");
  },
  saveToken(accessToken: string): void {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },
  removeToken(): void {
    localStorage.removeItem(TOKEN_KEY);
  },
  getRefreshToken(): void {
    return JSON.parse(localStorage.getItem(REFRESH_TOKEN_KEY) || "null");
  },
  saveRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },
  removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },
  getLanguage(): string | null {
    return localStorage.getItem(LANGUAGE_KEY) || null;
  },
  saveLanguage(language: string): void {
    localStorage.setItem(LANGUAGE_KEY, language);
  },
  getFirstAccess(): string {
    return JSON.parse(localStorage.getItem(FIRST_ACCESS) || "null");
  },
  saveFirstAccess(accessToken: string): void {
    localStorage.setItem(FIRST_ACCESS, accessToken);
  },
  removeFirstAccess(): void {
    localStorage.removeItem(FIRST_ACCESS);
  },
  getRole(): string {
    return localStorage.getItem(ROLE) || "null";
  },
  saveRole(role: string): void {
    localStorage.setItem(ROLE, role);
  },
  removeRole(): void {
    localStorage.removeItem(ROLE);
  },
  getTab(): string {
    return JSON.parse(localStorage.getItem(TAB) || "0");
  },
  saveTab(tab: string): void {
    localStorage.setItem(TAB, tab);
  },
  removeTab(): void {
    localStorage.removeItem(TAB);
  },
};

export { TokenService };
