import API from "../api";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async requestIsNotification(): Promise<boolean> {
      const { data } = await API.getIsNotification();
      return data;
    },
    async requestNotificationWasSeen(
      context: any,
      payload: number
    ): Promise<any> {
      const { data } = await API.getNotificationWasSeen(payload);
      return data;
    },
    async requestNotificationList(): Promise<any> {
      const { data } = await API.getNotificationList();
      return data;
    },
    async requestNotificationConfigure(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.putNotificationConfigure(payload);
      return data;
    },
    async requestNotificationConfig(): Promise<any> {
      const { data } = await API.getNotificationConfig();
      return data;
    },
    async requestIsMessageNotification(): Promise<boolean> {
      const { data } = await API.getIsMessageNotification();
      return data;
    },
  },
  modules: {},
};
