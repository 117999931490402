import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
@Component
export class ModalMixin extends Vue {
  currentScrollY = 0;
  @Action("updateClassModal") updateClassModal: any;
  blockScroll(): void {
    this.currentScrollY = window.scrollY;
  }
  fixScroll(): void {
    const mainId = document.getElementsByClassName(
      "v-application--wrap"
    ) as HTMLCollectionOf<HTMLElement>;
    if (mainId.length != 0) {
      mainId[0].style.overflow = "hidden";
      mainId[0].style.height = "0";
    }
    this.updateClassModal("transform-modal-view");
  }
  unblockScroll(): void {
    const mainId = document.getElementsByClassName(
      "v-application--wrap"
    ) as HTMLCollectionOf<HTMLElement>;
    if (mainId.length != 0) {
      mainId[0].style.overflow = "";
      mainId[0].style.height = "";
    }
    window.scrollTo(0, this.currentScrollY);
    this.updateClassModal("");
  }
}
