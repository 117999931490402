
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class BaseFilter extends Vue {
  @Prop({ default: "gray15" }) colorInput: string;
  @Prop({ default: true }) isAutoFocus: string;
  get defaultProp() {
    return {
      outlined: true,
      dense: true,
      "background-color": this.colorInput,
    };
  }
  get defaultConfigs(): any {
    return {
      ...this.$attrs,
      ...this.$props,
      ...this.defaultProp,
    };
  }
}
