import { api } from "@/api";
import { AxiosResponse } from "axios";
import { NewMessage, PayloadChat } from "../types";
class API {
  static postAcceptChat(payload: number): Promise<AxiosResponse> {
    return api.post(`/interaction/chat/${payload}/accept`);
  }
  static postMessage(payload: NewMessage): Promise<AxiosResponse> {
    return api.post("/interaction/chat/message", payload);
  }
  static getChats(): Promise<AxiosResponse> {
    return api.get("/interaction/chats");
  }
  static getCurrentChat(payload: PayloadChat): Promise<AxiosResponse> {
    return api.get(`/interaction/chat/${payload.chatId}/messages`, {
      params: { lastId: payload.lastId },
    });
  }
}

export default API;
