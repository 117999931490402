import App from "@/views/App.vue";

export default [
  {
    path: "/comunidade",
    name: "community",
    component: App,
    redirect: { name: "communityHome" },
    children: [
      {
        path: "/comunidade/:id",
        name: "communityHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Community.vue"),
        meta: {
          displayName: "Lounge - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/comunidade/membros/:id",
        name: "communityMembersHome",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/CommunityMembers.vue"
          ),
        meta: {
          displayName: "Membros do Lounge - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
