import { Locales } from "./locales";

import en from "./en.json";
import es from "./es.json";
import pt from "./pt.json";

export const messages = {
  [Locales.EN]: en,
  [Locales.ES]: es,
  [Locales.PT]: pt,
};

export const defaultLocale = Locales.PT;
