import { publicAxios } from "@/api/public";
import {
  PayloadAuth,
  PayloadLogin,
  PayloadOnboarding,
  PayloadEmail,
} from "../types";
import { AxiosResponse } from "axios";

class API {
  static getToken(payload: PayloadAuth): Promise<AxiosResponse> {
    return publicAxios.post("/authentication/authenticate", payload);
  }
  static getTokenUser(payload: PayloadOnboarding): Promise<AxiosResponse> {
    return publicAxios.get(`/user/onboarding/${payload.id}`, {
      params: payload.params,
    });
  }
  static getLogin(payload: PayloadLogin): Promise<AxiosResponse> {
    return publicAxios.post("/login", payload);
  }
  static postRecoverPassword(payload: PayloadEmail): Promise<AxiosResponse> {
    return publicAxios.post("/login/redefinir-senha", payload);
  }
}

export default API;
