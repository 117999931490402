import Vue from "vue";
import Vuex from "vuex";
import { TokenService } from "@/api/storage";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    alertNotification: {
      active: false,
      message: null,
      color: null,
      icon: null,
    },
    currentFilter: "",
    loadingDialog: false,
    isReturn: false,
    classModal: "",
    lastTab: 0,
    positionY: 0,
    lastItemsPost: [],
    lastItemsUser: [],
    filterPost: {
      interest: [],
      event: [],
      date: {
        type: "YEAR",
        id: 0,
      },
      community: [],
      query: "",
      type: {
        type: "",
        id: 0,
      },
    },
    filterUser: {
      query: "",
      interest: [],
      event: [],
      community: [],
    },
    lastPage: 0,
    lastRoute: "",
    routeAfterLogin: {
      name: "",
      params: {},
    },
    typePost: "",
    selectedEvent: [],
    selectedLounge: [],
    filterInput: "",
  },
  getters: {},
  mutations: {
    setAlertNotification(state, payload): void {
      state.alertNotification = payload;
      setTimeout(() => {
        state.alertNotification.active = false;
      }, 5000);
    },
    setCurrentFilter(state, payload): void {
      state.currentFilter = payload;
    },
    setLoadingDialog(state, payload): void {
      state.loadingDialog = payload;
    },
    setIsReturn(state, payload): void {
      state.isReturn = payload;
    },
    setClassModal(state, payload) {
      state.classModal = payload;
    },
    setLastTab(state, payload) {
      state.lastTab = payload;
    },
    setPositionY(state, payload) {
      state.positionY = payload;
    },
    setLastItemsPost(state, payload) {
      state.lastItemsPost = payload;
    },
    setLastItemsUser(state, payload) {
      state.lastItemsUser = payload;
    },
    setFilterPost(state, payload) {
      state.filterPost = payload;
    },
    setFilterUser(state, payload) {
      state.filterUser = payload;
    },
    setLastPage(state, payload) {
      state.lastPage = payload;
    },
    setLastRoute(state, payload) {
      state.lastRoute = payload;
    },
    setRouteAfterLogin(state, payload) {
      state.routeAfterLogin = payload;
    },
    setTypePost(state, payload) {
      state.typePost = payload;
    },
    setSelectedEvent(state, payload) {
      state.selectedEvent = payload;
    },
    setSelectedLounge(state, payload) {
      state.selectedLounge = payload;
    },
    setFilterInput(state, payload): void {
      state.filterInput = payload;
    },
  },
  actions: {
    showAlertNotification({ commit }, payload): void {
      payload.active = true;
      commit("setAlertNotification", payload);
    },
    requestLogout(): void {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
      TokenService.removeFirstAccess();
      TokenService.removeRole();
    },
    updateFilter({ commit }, payload: string): void {
      commit("setCurrentFilter", payload);
    },
    showLoadingIndicator({ commit }): void {
      commit("setLoadingDialog", true);
    },
    hideLoadingIndicator({ commit }): void {
      commit("setLoadingDialog", false);
    },
    updateReturn({ commit }, payload: boolean): void {
      commit("setIsReturn", payload);
    },
    updateClassModal({ commit }, payload): void {
      commit("setClassModal", payload);
    },
    updateLastTab({ commit }, payload): void {
      commit("setLastTab", payload);
    },
    updatePositionY({ commit }, payload): void {
      commit("setPositionY", payload);
    },
    updateLastItemsPost({ commit }, payload): void {
      commit("setLastItemsPost", payload);
    },
    updateLastItemsUser({ commit }, payload): void {
      commit("setLastItemsUser", payload);
    },
    updateFilterPost({ commit }, payload): void {
      commit("setFilterPost", payload);
    },
    updateFilterUser({ commit }, payload): void {
      commit("setFilterUser", payload);
    },
    updateLastPage({ commit }, payload): void {
      commit("setLastPage", payload);
    },
    updateLastRoute({ commit }, payload): void {
      commit("setLastRoute", payload);
    },
    updateRouteAfterLogin({ commit }, payload): void {
      commit("setRouteAfterLogin", payload);
    },
    updateTypePost({ commit }, payload): void {
      commit("setTypePost", payload);
    },
    updateSelectedEvent({ commit }, payload): void {
      commit("setSelectedEvent", payload);
    },
    updateSelectedLounge({ commit }, payload): void {
      commit("setSelectedLounge", payload);
    },
    updateFilterInput({ commit }, payload: string): void {
      commit("setFilterInput", payload);
    },
  },
  modules: {},
});
