
import { Component, Vue } from "vue-property-decorator";
@Component
export default class BaseButton extends Vue {
  get defaultConfigs(): any {
    return Object.assign(
      {
        dense: true,
        ripple: false,
      },
      {
        ...this.$attrs,
        ...this.$props,
      }
    );
  }
}
