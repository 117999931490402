import API from "../api";
import { PayloadPostsCommunity, PayloadStatusCommunity } from "../types";
import { Commit } from "vuex";
export default {
  namespaced: true,
  state: {
    notificationNewCommunity: false,
  },
  mutations: {
    setNotification(state: any, payload: boolean) {
      state.notificationNewCommunity = payload;
    },
  },
  actions: {
    async requestCurrentCommunity(context: any, payload: number): Promise<any> {
      const { data } = await API.getCurrentCommunity(payload);
      return data;
    },
    async requestFeedCommunity(
      context: any,
      payload: PayloadPostsCommunity
    ): Promise<any> {
      const { data } = await API.getFeedCommunity(payload);
      return data;
    },
    async requestCommunities(): Promise<any> {
      const { data } = await API.getCommunities();
      return data;
    },
    async requestCommunityMembers(context: any, payload: any): Promise<any> {
      const { data } = await API.getCommunityMembers(payload);
      return data;
    },
    async requestUpdateStatusCommunity(
      context: any,
      payload: PayloadStatusCommunity
    ): Promise<any> {
      const { data } = await API.putStatusCommunity(payload);
      return data;
    },
    async requestIsNewCommunity(): Promise<any> {
      const { data } = await API.getIsNewCommunity();
      return data;
    },
    async requestRemoveNotificationCommunity(): Promise<any> {
      const { data } = await API.putNotificationCommunity();
      return data;
    },
    async requestCommunitiesActives(): Promise<any> {
      const { data } = await API.getCommunitiesActives();
      return data;
    },
    async requestCommunityPending({
      commit,
    }: {
      commit: Commit;
    }): Promise<any> {
      const { data } = await API.getCommunityPending();
      commit("setNotification", data);
      return data;
    },
    async requestNewCommunity(context: any, payload: any): Promise<any> {
      const { data } = await API.postNewCommunity(payload);
      return data;
    },
    async requestEditCommunity(context: any, payload: any): Promise<any> {
      const { data } = await API.putEditCommunity(payload);
      return data;
    },
    async requestInactivateCommunity(context: any, payload: any): Promise<any> {
      const { data } = await API.putInactivateCommunity(payload);
      return data;
    },
    async requestUsersToInvite(context: any, payload: any): Promise<any> {
      const { data } = await API.getUsersToInvite(payload);
      return data;
    },
    async requestInviteUser(context: any, payload: any): Promise<any> {
      const { data } = await API.putInviTeUser(payload);
      return data;
    },
    async requestInativateUser(context: any, payload: any): Promise<any> {
      const { data } = await API.putInactivateUser(payload);
      return data;
    },
  },
  modules: {},
};
