import API from "../api";
import { ChatItem, ChatMessage, NewMessage, PayloadChat } from "../types";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async requestChats(): Promise<ChatItem[]> {
      const { data } = await API.getChats();
      return data;
    },
    async requestNewMessage(context: any, payload: NewMessage): Promise<any> {
      const { data } = await API.postMessage(payload);
      return data;
    },
    async requestAcceptChat(context: any, payload: number): Promise<any> {
      const { data } = await API.postAcceptChat(payload);
      return data;
    },
    async requestCurrentChat(
      context: any,
      payload: PayloadChat
    ): Promise<ChatMessage[]> {
      const { data } = await API.getCurrentChat(payload);
      return data;
    },
  },
  modules: {},
};
