import { api } from "@/api";
import { AxiosResponse } from "axios";
import {
  PayloadDenounce,
  PayloadReaction,
  PayloadComment,
  PayloadReactionComment,
  PayloadAnswer,
  PayloadEditComment,
  PayloadEditAnswer,
  PayloadDenounceComment,
  PayloadAuth,
  PayloadTimeline,
  PayloadCA,
  PayloadOnboarding,
  PayloadAcceptTerms,
  PayloadIsRead,
} from "../types";
import { publicAxios } from "@/api/public";
class API {
  static getToken(payload: PayloadAuth): Promise<AxiosResponse> {
    return publicAxios.post("/authentication/authenticate", payload);
  }
  static getTokenUser(payload: PayloadOnboarding): Promise<AxiosResponse> {
    return publicAxios.get(`/user/onboarding/${payload.id}`, {
      params: payload.params,
    });
  }
  static getTimeline(payload: any): Promise<AxiosResponse> {
    return api.get("/post/feed", { params: payload });
  }
  static getReactions(payload: string): Promise<AxiosResponse> {
    return api.get(`/post/${payload}/reactions`);
  }
  static getDenounce(): Promise<AxiosResponse> {
    return api.get("/denunciation/reasons");
  }
  static postDenounce(payload: PayloadDenounce): Promise<AxiosResponse> {
    return api.post("/denunciation/post", payload);
  }
  static postReactionPost(payload: PayloadReaction): Promise<AxiosResponse> {
    return api.post("/interaction/react-post", payload);
  }
  static getCurrentPost(payload: string): Promise<AxiosResponse> {
    return api.get(`/post/${payload}`);
  }
  static postComment(payload: PayloadComment): Promise<AxiosResponse> {
    return api.post("/interaction/comment", payload);
  }
  static postReactionComment(
    payload: PayloadReactionComment
  ): Promise<AxiosResponse> {
    return api.post("/interaction/react-comment", payload);
  }
  static postAnswer(payload: PayloadAnswer): Promise<AxiosResponse> {
    return api.post("/interaction/reply", payload);
  }
  static getReactionsComment(payload: string): Promise<AxiosResponse> {
    return api.get(`/post/comment/${payload}/reactions`);
  }
  static putComment(payload: PayloadEditComment): Promise<AxiosResponse> {
    return api.put(`/interaction/comment/${payload.id}`, payload);
  }
  static putAnswer(payload: PayloadEditAnswer): Promise<AxiosResponse> {
    return api.put(`/interaction/reply/${payload.id}`, payload);
  }
  static deleteComment(payload: string): Promise<AxiosResponse> {
    return api.delete(`/interaction/comment/${payload}`);
  }
  static postDenouncComment(
    payload: PayloadDenounceComment
  ): Promise<AxiosResponse> {
    return api.post("/denunciation/comment", payload);
  }
  static postInactivate(payload: any): Promise<AxiosResponse> {
    return api.post("/inactivation/post", payload);
  }
  static getTimelineUser(payload: PayloadTimeline): Promise<AxiosResponse> {
    return api.get(`/post/${payload.userId}/posts`, { params: payload });
  }
  static postInactivateComment(payload: any): Promise<AxiosResponse> {
    return api.post("/inactivation/comment", payload);
  }
  static postRegisterUserCA(payload: PayloadCA): Promise<AxiosResponse> {
    return api.post(`/post/register-user-ca`, payload);
  }
  static getUpdateStatusVideo(payload: number): Promise<AxiosResponse> {
    return api.get(`/media/${payload}/set-video-is-played`);
  }
  static getRegisterUserPost(payload: number): Promise<AxiosResponse> {
    return api.get(`/post/metrics/${payload}`);
  }
  static putDeletePost(payload: number): Promise<AxiosResponse> {
    return api.put(`/post/inactivate/${payload}`);
  }
  static getPostAnswer(payload: number | string): Promise<AxiosResponse> {
    return api.get(`/post/answer/${payload}`);
  }
  static getFixPost(payload: any): Promise<AxiosResponse> {
    return api.get(`/post/fix-post/${payload.id}`, { params: payload.params });
  }
  static postSuggestionInterest(payload: any): Promise<AxiosResponse> {
    return api.post("/interest/add-new-interest-by-user", payload);
  }
  static getFeed(payload: any): Promise<AxiosResponse> {
    return api.get("/post/api/v2/feed", { params: payload });
  }
  static getForYou(payload: any): Promise<AxiosResponse> {
    return api.get("/post/api/v2/for-you", { params: payload });
  }
  static getAcceptTerms(payload: PayloadAcceptTerms): Promise<AxiosResponse> {
    return api.get("/user/accept-term", { params: payload });
  }
  static getTimelineFourNetwork(payload: any): Promise<AxiosResponse> {
    return api.get("/post/by-fournetwork", { params: payload });
  }
  static getTerms(): Promise<AxiosResponse> {
    return api.get("/terms");
  }
  static getAbout(): Promise<AxiosResponse> {
    return api.get("/about/");
  }
  static postReadingList(payload: PayloadIsRead): Promise<AxiosResponse> {
    return api.post(`/post/set-is-read${payload}`);
  }
  static getReadingList(payload: any): Promise<AxiosResponse> {
    return api.get("/post/get-reads", { params: payload });
  }
  static getCaseUser(payload: any): Promise<AxiosResponse> {
    return api.get(`/post/${payload.id}/success-case`, {
      params: payload.params,
    });
  }
  static putFeature(payload: string): Promise<AxiosResponse> {
    return api.put(`/feature/${payload}`, { params: payload });
  }
}

export default API;
