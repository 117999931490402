export enum Locales {
  EN = "en",
  ES = "es",
  PT = "pt",
}

export const LOCALES = [
  { value: Locales.EN, caption: "English" },
  { value: Locales.ES, caption: "Español" },
  { value: Locales.PT, caption: "Português" },
];
