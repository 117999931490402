import { api } from "@/api";
import { AxiosResponse } from "axios";
class API {
  static getIsNotification(): Promise<AxiosResponse> {
    return api.get("/notification/verify");
  }
  static getNotificationWasSeen(params: number): Promise<AxiosResponse> {
    return api.get(`/notification/update/${params}`);
  }
  static getNotificationList(): Promise<AxiosResponse> {
    return api.get("/notification/by-user");
  }
  static putNotificationConfigure(payload: any): Promise<AxiosResponse> {
    return api.put("/notification/configure", payload);
  }
  static getNotificationConfig(): Promise<AxiosResponse> {
    return api.get("/notification/configurations");
  }
  static getIsMessageNotification(): Promise<AxiosResponse> {
    return api.get("/notification/message-verify");
  }
}

export default API;
