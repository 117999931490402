import { api } from "@/api";
import { AxiosResponse } from "axios";

class API {
  static postNewRoundTable(payload: any): Promise<AxiosResponse> {
    return api.post("/debate", payload);
  }
  static postNewPostRoundTable(payload: any): Promise<AxiosResponse> {
    return api.post("/debate/post", payload);
  }
  static putEditPostRoundTable(payload: any): Promise<AxiosResponse> {
    return api.put("/debate/post", payload);
  }
  static getRoundTable(payload: any): Promise<AxiosResponse> {
    return api.get(`/debate/${payload.id}`, { params: payload.params });
  }
  static postCommentRoundTable(payload: any): Promise<AxiosResponse> {
    return api.post(
      `/interaction/debate/${payload.postId}/comment`,
      payload.params
    );
  }
  static putCommentRoundTable(payload: any): Promise<AxiosResponse> {
    return api.put(
      `/interaction/debate/${payload.postId}/comment/${payload.commentId}`,
      payload.params
    );
  }
  static deleteCommentRoundTable(payload: any): Promise<AxiosResponse> {
    return api.delete(
      `/interaction/debate/${payload.postId}/comment/${payload.commentId}`
    );
  }
}
export default API;
