import App from "@/views/App.vue";

export default [
  {
    path: "/round-table",
    name: "roundTable",
    component: App,
    redirect: { name: "roundTableHome" },
    children: [
      {
        path: "/novo-round-table",
        name: "newRoundTableHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewRoundTable.vue"),
        meta: {
          displayName: "Novo Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/novo-post-round-table/:id",
        name: "newPostRoundTable",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/NewPostRoundTable.vue"
          ),
        meta: {
          displayName: "Novo Post Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/editar-post-round-table/:id",
        name: "editPostRoundTable",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/EditPostRoundTable.vue"
          ),
        meta: {
          displayName: "Editar Post Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/debate/:id",
        name: "roundTableItems",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/RoundTable.vue"),
        meta: {
          displayName: "Debate - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
