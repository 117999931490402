
import { Component } from "vue-property-decorator";
import { Route } from "vue-router";
import { mixins } from "vue-class-component";
import { ModalMixin } from "../mixins/ModalMixin";
import { State } from "vuex-class";
@Component({
  watch: {
    $route: {
      handler: function (to: Route): void {
        document.title = to?.meta?.displayName || "Exclusive Lounge";
      },
      immediate: true,
    },
  },
})
export default class App extends mixins(ModalMixin) {
  @State("classModal") classModal: any;
}
