
const defaultProp = {
  outlined: true,
  class: "mt-2 caption",
  dense: true,
  "background-color": "white",
};
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class BaseFormTextArea extends Vue {
  @Prop(String) idElement: string;
  @Prop(String) labelText: string;
  @Prop({ default: "justify-start" }) labelPosition: string;
  get defaultConfigs(): any {
    return {
      ...this.$attrs,
      ...this.$props,
      ...defaultProp,
    };
  }
}
