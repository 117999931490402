import Vue from "vue";
import VueRouter from "vue-router";
import { TokenService } from "@/api/storage";
import { initialize } from "@/api";
import Store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home/:user/:language?",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    meta: {
      displayName: "Home - Exclusive Lounge",
      requiresAuth: false,
      title: "Exclusive Lounge",
    },
  },
  {
    path: "/pagina-nao-encontrada",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotFound.vue"),
    meta: {
      displayName: "404 - Exclusive Lounge",
      requiresAuth: false,
      title: "Exclusive Lounge",
    },
  },
  {
    path: "/pagina-sem-autenticacao",
    name: "notAuth",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotAuth.vue"),
    meta: {
      displayName: "404 - Exclusive Lounge",
      requiresAuth: false,
      title: "Exclusive Lounge",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next({ name: "notFound" });
  } else if (to?.meta?.requiresAuth) {
    // redirect to login if the user to try acess any other route without login
    if (
      !TokenService.getToken() ||
      (Boolean(TokenService.getFirstAccess()) !== false &&
        to.name !== "profileStep" &&
        from.name !== "loginPage" &&
        to.name !== "timelineHome" &&
        from.name !== "home")
    ) {
      Store.dispatch("updateRouteAfterLogin", to);
      next({ name: "loginPage" });
    } else {
      initialize(TokenService.getToken());
      const isReturn =
        (to.name === "settingsHome" && from.name === "settingsMetricsPost") ||
        (["searchHome", "timelineHome", "readingListHome"].includes(
          `${to.name}`
        ) &&
          [
            "profileCLevel",
            "profileMarketing",
            "timelinePost",
            "communityHome",
            "searchTagId",
            "roundTableItems",
            "newPostRoundTable",
          ].includes(`${from.name}`) &&
          (Store.state.lastItemsPost.length > 0 ||
            Store.state.lastItemsUser.length > 0));
      Store.dispatch("updateReturn", isReturn);
      if (from.name === "searchHome") Store.dispatch("updateFilter", "");
      if (from.name !== null) {
        TokenService.saveTab("0");
      }
      next();
    }
  } else if (TokenService.getToken() && !TokenService.getFirstAccess()) {
    initialize(TokenService.getToken());
    next({ name: "timelineHome" });
  } else {
    next();
  }
});

export default router;
