
const defaultProp = {
  outlined: true,
  class: "mt-2 caption",
  dense: true,
  "background-color": "white",
};
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class BaseFormInput extends Vue {
  @Prop(String) idElement: string;
  @Prop(String) labelText: string;
  @Prop({ default: "justify-start" }) labelPosition: string;
  @Prop({ default: false }) eye: boolean;
  @Prop(Boolean) eyeValue: boolean;
  activeEye = false;
  get imageButton(): any {
    return this.eyeValue
      ? require("@/assets/globeOn.svg")
      : require("@/assets/globeOff.svg");
  }
  get defaultConfigs(): any {
    return {
      ...this.$attrs,
      ...this.$props,
      ...defaultProp,
    };
  }
  changeEye(): void {
    this.$emit("update", !this.eyeValue);
  }
}
