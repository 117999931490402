
const defaultProp = {
  outlined: true,
  class: "mt-2 caption",
  dense: true,
  "background-color": "white",
};
import Component, { mixins } from "vue-class-component";
import { ActionMixin } from "../../mixins/ActionMixin";
import { Action } from "vuex-class";
@Component
export default class BaseAutocompleteEvent extends mixins(ActionMixin) {
  @Action("profile/requestAllEvents") requestAllEvents: any;
  items: any[] = [];
  loading = false;
  filter = "";
  get defaultConfigs(): any {
    return {
      ...this.$attrs,
      ...this.$props,
      ...defaultProp,
    };
  }
  created(): void {
    this.updateMentionList();
  }
  async updateMentionList(): Promise<void> {
    this.loading = true;
    await this.withLoading(async () => {
      await this.requestAllEvents()
        .then((data: any) => {
          this.items = data;
        })
        .finally(() => {
          this.loading = false;
        });
    });
  }
  resetFilter(): void {
    this.filter = "";
  }
}
