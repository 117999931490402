import MainStore from "@/store";
import MainRouter from "@/router";

import localStore from "./store";
import localRoutes from "./router";

MainStore.registerModule("timeline", localStore);
localRoutes.forEach(function (route) {
  MainRouter.addRoute(route);
});
