
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class SidebarItem extends Vue {
  @Prop(Object) item: any;
  @Prop({ default: false }) mini: boolean;
  @Prop({ default: false }) selected: boolean;
  get colorText(): string {
    return this.selected ? "purple100--text font-weight-bold" : "gray70--text";
  }
  get classButton(): string {
    return this.mini ? "button-sidebar-item" : "";
  }
  get customIcon(): any {
    return this.selected ? this.item.imageSelected : this.item.image;
  }
}
